.dekka-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px 80px 0 80px;
}

.dekka-heading h1 {
  font-family: DM Sans;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: -7.68px;
  text-transform: capitalize;
  text-align: center;
}

.dekka-heading img {
  width: 100%;
  max-width: 777px;
}

.dekka-heading .dk-btn-beli {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  background: #44a3db;
  box-shadow: 0px 8px 20px 0px rgba(0, 148, 255, 0.4);
  font-weight: 600;
  font-size: 20px;
  font-family: Poppins;
  margin-top: 40px;
  margin-bottom: 80px;
}

.dekka-scroll-horizontal {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 30px;
  padding: 0 80px;
  margin-top: 160px;
}

.dekka-card {
  width: 100%;
  height: 360px;
  background-color: #ecf6fb;
  display: flex;
  flex-direction: column;
  justify-content: end;
  border-radius: 8px;
}

.dekka-card-content {
  padding: 20px 30px;
}

.dekka-card-content img {
  width: 40px;
  height: 40px;
}

.dekka-card-content h1 {
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.96px;
}

.dekka-card-content p {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
}

.dekka-video-day-night {
  position: relative;
  padding: 0 80px;
  margin-top: 160px;
}

.dekka-video-day-night .dekka-video-title {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  border-radius: 8px;
  padding: 20px;
  z-index: 1;
  background: rgba(0, 24, 56, 0.40);
}

.dekka-video-day-night .dekka-video-title h1 {
  font-family: DM Sans;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 80% */
  letter-spacing: -2.4px;
  text-transform: capitalize;
  text-align: center;
}

.dekka-video-day-night .dekka-video-title .dekka-video-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 50%;
  margin-inline: auto;
  margin-top: 10px;
}

.dekka-video-day-night .dekka-video-title .dekka-video-btn .btn-video {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #000d1f;
  border-radius: 4px;
  flex: 1;
  color: #ecf6fb;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.active-btn-video {
  background: #44a3db;
}

.dk-video-day-night-gallery {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 20px;
}

.dekka-video-day-night video {
  border-radius: 10px;
  transition: 300ms;
}

.dekka-features-gif {
  padding: 20px 80px;
}

.dekka-features-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  gap: 40px;
}

.dekka-features-content .dekka-features-card {
  width: 50%;
}

.dekka-features-content:nth-child(2) div {
  order: 2;
}

.dekka-features-content:nth-child(2) img {
  order: 1;
}

.dekka-features-content h1 {
  font-family: DM Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.6px;
  text-transform: capitalize;
}

.dekka-features-content p {
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.48px;
}

.dekka-features-content img {
  width: 50%;
  border-radius: 8px;
}

.dekka-night-vision {
  padding: 0 80px;
  margin-top: 160px;
}

.dekka-night-vision video {
  border-radius: 10px;
}

.dekka-night-vision div {
  text-align: center;
  margin-bottom: 20px;
}

.dekka-night-vision div h1 {
  font-family: DM Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 100% */
  letter-spacing: -1.6px;
  text-transform: capitalize;
  margin-top: 8px;
}
.dekka-night-vision div p {
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 166.667% */
  letter-spacing: -0.48px;
}

.scrollToTop {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.scrollToTop-btn img {
  width: 80px;
  height: 80px;
  transition: 300ms;
}

.scrollToTop-btn:hover img {
  transform: scale(1.1);
  transition: 300ms;
}

.dekka-scroll-horizontal-mobile {
  display: none;
}

.dk-screen {
  padding: 0 80px;
  margin-top: 160px;
  text-align: center;
}

.dk-screen-img-wraper img {
  width: 100%;
}

.dk-screen-title {
  font-family: DM Sans;
  font-size: 60px;
  font-weight: 700;
  line-height: 48px;
  margin-top: 40px;
}

.dk-screen-caption {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  margin-top: 9px;
  color: #44a3db;
}

.dk-speaker-mic {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: 0 80px;
  margin-top: 160px;
}
.dk-speaker-mic.dk25 {
  gap: 80px;
}

.dk-speaker-desc-wraper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dk-speaker-title {
  font-family: DM Sans;
  font-size: 60px;
  font-weight: 700;
  line-height: 48px;
}

.dk-speaker-caption {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #44a3db;
}

.dk-speaker-img-wraper img {
  width: 100%;
}

.dk-core-feature {
  padding: 0 80px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-top: 80px;
}

.dk-core-feature-container {
  padding: 0 80px;
}

.dk-core-feature h2 {
  margin: 8px 0;
  font-size: 24px;
  font-weight: 700;
}

.dk-core-feature:nth-child(even) .dk-core-feature-video-wraper {
  order: 1;
}

.dk-core-feature:nth-child(even) .dk-core-feature-video-wraper video {
  border-radius: 20px 0 0 20px;
}

.dk-core-feature:nth-child(even) .dk-core-feature-card {
  order: 2;
  border-radius: 0 20px 20px 0;
}

.dk-core-feature:nth-child(odd) .dk-core-feature-video-wraper video {
  overflow: hidden;
  border-radius: 0 20px 20px 0;
}

.dk-core-feature:nth-child(odd) .dk-core-feature-card {
  border-radius: 20px 0 0 20px;
}

.dk-core-feature .dk-core-feature-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 40px;
  background: linear-gradient(180deg, #000a18 0%, #001838 45.32%, #000a18 100%);
}

.dk-slide-up-section {
  padding: 0 80px;
  margin: 80px 0;
  display: flex;
  justify-content: flex-end;
}

.dk-slide-up-button {
  padding: 20px;
  border-radius: 8px;
  background: #44A3DB;
  border-style: none;
}

@media screen and (max-width: 991.98px) {
  .dekka-heading {
    padding: 120px 20px 0 20px;
  }

  .dekka-heading img {
    width: 90%;
  }

  .dekka-scroll-horizontal {
    padding: 0 20px;
  }

  .dk-screen {
    padding: 0 20px;
    text-align: center;
  }

  .dk-speaker-mic {
    padding: 0 20px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    text-align: center;
  }
  .dk-speaker-mic.dk25 {
    gap: 0;
  }

  .dk-speaker-img-wraper {
    order: 1;
  }

  .dk-speaker-desc-wraper {
    order: 2;
  }

  .dk-speaker-title {
    margin-top: 40px;
  }

  .dekka-video-day-night {
    position: static !important;
    padding: 0 20px;
  }

  .dekka-video-day-night .dekka-video-title {
    position: static !important;
    left: auto;
    transform: none;
    z-index: auto;
    text-align: center;
    background: none;
  }

  .dekka-video-day-night .dekka-video-title .dekka-video-btn {
    width: auto;
    margin: 20px 0;
  }

  .dekka-video-day-night .dekka-video-title .dekka-video-btn img {
    width: 20px;
  }

  .dekka-night-vision {
    padding: 0 20px;
  }

  .dk-core-feature {
    padding: 0 20px;
  }

  .dk-core-feature-container {
    padding: 0 20px;
  }

  .dk-core-feature .dk-core-feature-card {
    padding: 20px;
  }

  .dk-slide-up-section {
    padding: 0 20px;
  }
}

@media screen and (max-width: 767.98px) {
  .dekka-heading {
    padding: 150px 20px 0 20px;
  }

  .dekka-scroll-horizontal {
    display: none;
  }

  .dekka-scroll-horizontal-mobile {
    display: block;
    padding: 0 20px;
    margin-top: 80px;
  }

  .dk-core-feature {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .dk-core-feature-card {
    border-radius: 0 0 20px 20px !important;
  }

  .dk-core-feature-video-wraper video {
    border-radius: 20px 20px 0 0 !important;
  }

  .dk-core-feature:nth-child(odd) .dk-core-feature-card {
    order: 2;
  }

  .dk-core-feature:nth-child(odd) .dk-core-feature-video-wraper {
    order: 1;
  }
  
}

@media screen and (max-width: 600px) {
  .dekka-heading h1 {
    font-family: DM Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 100% */
    letter-spacing: -1.6px;
    text-transform: capitalize;
  }

  .dekka-heading .dk-btn-beli {
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .dekka-features-gif {
    padding: 10px;
  }

  .dekka-features-content {
    flex-direction: column;
  }

  .dk-feature-icon {
    width: 40px;
  }

  .dekka-features-content:nth-child(2) div {
    order: inherit;
  }

  .dekka-features-content:nth-child(2) img {
    order: inherit;
  }

  .dekka-features-content img {
    width: 100% !important;
  }

  .dk-screen {
    margin-top: 80px;
  }

  .dk-screen-title {
    font-size: 40px;
    line-height: 40px;
  }

  .dk-screen-caption {
    font-size: 20px;
    font-weight: 400;
  }

  .dk-speaker-mic {
    margin-top: 80px;
  }

  .dk-speaker-title {
    font-size: 40px;
    line-height: 40px;
  }

  .dk-speaker-caption {
    font-size: 20px;
    font-weight: 400;
  }

  .dekka-video-day-night {
    margin-top: 80px;
  }

  .dekka-video-day-night .dekka-video-title h1 {
    font-family: DM Sans;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 100% */
    letter-spacing: -1.6px;
    text-transform: capitalize;
  }

  .dekka-night-vision {
    margin-top: 80px;
  }

  .dk-core-feature {
    margin-top: 40px;
  }

  .dk-slide-up-section {
    display: none;
  }

}
