.article {
  background: #001128;
  border: 1px solid #334660;
  padding: 32px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 32px;
}
.articleImageContainer {
  aspect-ratio: 1.78/1;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
}
.articleImage {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.articleCotent {
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: space-between;
}
.articleContentHeader {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.articleTag {
  display: flex;
  gap: 16px;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}
.articleTagItem {
  padding: 0 16px;
  background: #000a18;
  border: 1px solid #b0b7c1;
  border-radius: 8px;
  font-size: 16px;
  font-family: Poppins;
  margin: 0;
}
.articleTitle {
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.articleDate {
  font-family: Poppins;
  font-size: 16px;
  color: #44a3db;
  margin: 0;
}
.articleDescription {
  font-size: 16;
  font-family: Poppins;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.articleLink {
  width: 100%;
  margin: 0;
}
.articleButton {
  width: 100%;
  border-radius: 4px;
  padding: 8px 24px;
  background: #44a3db;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  border: none;
}

@media screen and (max-width: 600px) {
  .article {
    padding: 16px;
  }
  .articleTitle {
    font-size: 24px;
  }
}
