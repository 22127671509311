/* helper class */
.btn-mobile {
  display: none !important;
}
.btn-dekstop {
  display: inline-block !important;
}

.home-hero-gradient {
  background: linear-gradient(180deg, #000a18 0%, #001838 45.32%, #000a18 100%);
}

.home-hero {
  display: flex;
  align-items: center;
  padding: 0 80px;
  gap: 20px;
  margin-top: 122px;
}

.home-hero img {
  width: 80%;
  margin: auto 0 auto auto;
}

.home-hero-img {
  width: 100%;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  aspect-ratio: 1.3/0.8;
}

.home-content {
  color: #ecf6fb;
  flex-basis: 700px;
  flex-shrink: 0;
}

.home-content h1 {
  font-family: DM Sans;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: 104.167%;
  letter-spacing: -7.68px;
  margin: 0;
}

.home-content p {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.48px;
  width: 100%;
  margin: 40px 0;
}

a.home-hero-btn-ss {
  text-decoration: none;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  color: #64B3E1;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  padding: 6px 12px;
  border: 2px solid #44A3DB;
  border-radius: 8px;
}
a.home-hero-btn-ss span {
  margin: 0;
  padding: 0;
}

.home-hero-button {
  display: flex;
  flex-direction: column;
  width: max-content;
  justify-content: start;
  gap: 20px;
}

.buy-dekka-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #44a3db;
  box-shadow: 0px 8px 20px 0px rgba(0, 148, 255, 0.4);
  font-weight: 600;
  font-size: 20px;
  font-family: Poppins;
}

.home-include {
  padding: 0 80px;
  padding-top: 160px;
}

.include-title h1 {
  font-family: DM Sans;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 80%;
  letter-spacing: -2.4px;
  text-transform: capitalize;
  color: #ecf6fb;
}

.include-title h1 span {
  font-style: italic;
}

.include-title p {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.4px;
  color: #44a3db;
}

.include-items {
  margin-top: 40px;
  padding: 40px;
  border-radius: 20px;
  border: 1px solid #64b3e1;
  background: conic-gradient(
    from 180deg at 50% 50%,
    #001128 0deg,
    #000a18 166.25deg,
    #001633 239.38deg,
    #001128 360deg
  );
}

.include-items-group {
  display: flex;
}

.include-item {
  width: 33%;
}

.include-separator {
  width: 1px;
  background-color: #64b3e1;
  margin: 0 40px;
}

.include-separator-bottom {
  width: 100%;
  height: 1px;
  background-color: #64b3e1;
  margin: 40px 0;
}

.inclue-item-title {
  font-family: DM Sans;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  margin: 20px 0;
}

.include-item-caption {
  font-family: Poppins;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

.home-features {
  margin-top: 160px;
  padding: 0 80px;
}

.features-title h1 {
  font-family: DM Sans;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 80%;
  letter-spacing: -2.4px;
  text-transform: capitalize;
  color: #ecf6fb;
  margin-bottom: 40px;
}

.features-title p {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.4px;
  color: #44a3db;
}

.features-card-container {
  display: flex;
}

.features-left,
.features-right {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.features-right .features-card {
  height: 100%;
  background: linear-gradient(180deg, #000a18 0%, #001838 45.32%, #000a18 100%);
  margin: 5px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
}

.features-left .features-card {
  height: 100%;
  background: linear-gradient(180deg, #000a18 0%, #001838 45.32%, #000a18 100%);
  margin: 5px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}

.features-card .desc-wrapper img {
  object-fit: cover;
}
.features-card > img {
  width: 100%;
}

.desc-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.features-desc {
  margin-top: 10px;
}

.features-desc h4 {
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 133.333%;
  letter-spacing: 0.96px;
}
.features-desc p {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
}

.home-dashcam {
  margin-top: 160px;
  padding: 0 80px;
}

.dashcam-title h1 {
  font-family: DM Sans;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 80%;
  letter-spacing: -2.4px;
  text-transform: capitalize;
  color: #ecf6fb;
}

.dashcam-title p {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.4px;
  color: #44a3db;
  margin-bottom: 40px;
}

.home-dashcam .dk {
  display: flex;
  gap: 50px;
  padding-bottom: 60px;
  margin-bottom: 60px;
  border-bottom: 2px solid #44a3db;
}

.home-dashcam .dk:nth-child(3) {
  flex-direction: row-reverse;
}

.home-dashcam .dk:nth-child(5) {
  flex-direction: row-reverse;
}

.home-dashcam .dk:last-child {
  margin-bottom: 0;
}

.home-dashcam .dk .dk-left {
  display: flex;
  flex-direction: column;
}

.home-dashcam .dk .dk-left h1 {
  color: #64b3e1;
  font-family: DM Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 100% */
  letter-spacing: -1.6px;
  text-transform: capitalize;
}

.home-dashcam .dk .dk-left h2 {
  font-family: DM Sans;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 80% */
  letter-spacing: -2.4px;
  text-transform: capitalize;
}

.home-dashcam .dk .dk-left img {
  width: 520px;
  height: 390px;
  object-fit: cover;
}

.home-dashcam .dk .dk-left img.dk25 {
  width: 520px;
  height: 262px;
  object-fit: cover;
}

.home-dashcam .dk .dk-right .dk-desc h5 {
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  letter-spacing: 0.96px;
}
.home-dashcam .dk .dk-right .dk-desc p {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
}

.home-dashcam .dk .dk-right .dk-desc img {
  margin-bottom: 15px;
  width: 50px;
}

.home-dashcam .dk .dk-right .dk-desc .dual-camera {
  width: 80px !important;
}

.home-dashcam .dk .dk-right .dk-desc-btn {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}

.home-dashcam .dk .dk-right .dk-desc-btn .dk-btn-pelajari {
  flex: 1;
  border-radius: 8px;
  border: 2px solid #44a3db;
  color: #44a3db;
  background: none;
  box-shadow: 0px 8px 20px 0px rgba(0, 148, 255, 0.4);
  font-weight: 600;
  font-size: 20px;
  font-family: Poppins;
}

.home-dashcam .dk .dk-right .dk-desc-btn .dk-btn-beli {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  background: #44a3db;
  box-shadow: 0px 8px 20px 0px rgba(0, 148, 255, 0.4);
  font-weight: 600;
  font-size: 20px;
  font-family: Poppins;
  width: 100%;
  max-width: 250px;
}

.home-table {
  margin-top: 0 !important;
  padding: 20px 80px;
}

.table-header div:first-child {
  display: flex;
  align-self: center;
}

.table-header div:nth-child(n + 2) {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}

.table-header div:first-child img {
  width: 297px;
}

.table-header div:nth-child(n + 2) img {
  max-width: 200px;
  width: 92% !important;
}

.table-header div:nth-child(n + 2) p {
  font-family: DM Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.6px;
  text-transform: capitalize;
}

.table-content:nth-child(odd) div {
  background: #001838;
}

.table-content:nth-child(even) div {
  background: #001128;
}

.table-content div {
  padding: 10px;
  padding-left: 20px;
}

.table-content div:nth-child(even) {
  border-left: 2px solid #000a18;
  border-right: 2px solid #000a18;
}

.table-content div:first-child span {
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  letter-spacing: 0.96px;
}

.table-content div:nth-child(1) {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.table-content div:nth-child(n + 2) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wifi-column {
  gap: 8px;
  flex-direction: column;
}

.table-content div:nth-child(n + 2) img {
  width: 32px;
  height: 32px;
}

.table-content div:nth-child(n + 2) {
  text-align: center;
}

.slide-up-section {
  padding: 0 80px;
  margin: 80px 0;
  display: flex;
  justify-content: end;
}

.slide-up-button {
  padding: 20px;
  border-radius: 8px;
  background: #44a3db;
  border-style: none;
}

@media screen and (max-width: 1536px) {
  .home-hero {
    display: flex;
    align-items: center;
    padding: 0 80px;
    gap: 20px;
    margin-top: 122px;
  }

  .home-hero img {
    width: 100%;
    max-width: 720px;
    max-height: 556px;
    margin: auto 0 auto auto;
  }

  .home-hero-img {
    width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    aspect-ratio: 1.3/1;
  }

  .home-content {
    color: #ecf6fb;
    flex-basis: 500px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .home-content p {
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.48px;
    width: 100%;
    max-width: 400px;
    margin: 40px 0;
  }
}

@media screen and (max-width: 1199.98px) {
  .include-items-group {
    display: flex;
    flex-direction: column;
  }

  .include-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .include-separator {
    width: 100%;
    height: 1px;
    margin: 40px 0;
  }

  .home-dashcam .dk:nth-child(3) {
    flex-direction: column;
  }

  .home-dashcam .dk {
    flex-direction: column;
  }

  .home-dashcam .dk .dk-left {
    align-items: center;
  }

  .home-dashcam .dk .dk-right .dk-desc-btn {
    width: 100% !important;
    flex-direction: row;
  }

  .home-dashcam .dk .dk-right .dk-desc-btn .dk-btn-beli {
    max-width: none;
  }

  .table-header div:first-child img {
    width: 200px;
  }
}

@media screen and (max-width: 991.98px) {
  .home-hero {
    flex-direction: column-reverse;
    padding: 0 20px;
    gap: 50px;
  }

  .home-content {
    flex-basis: auto;
  }

  .home-content h1 {
    text-align: center;
  }

  .home-content p {
    text-align: center;
    width: 100%;
    max-width: none;
  }

  .home-hero img {
    width: 90%;
    margin: auto;
  }

  .home-hero-button {
    justify-content: center;
    margin: 0 auto 0 auto;
  }

  .home-include {
    padding: 0 20px;
    padding-top: 160px;
  }

  .home-features {
    padding: 0 20px;
  }

  .home-dashcam {
    padding: 0 20px;
  }

  .home-table {
    padding: 0 30px;
  }

  .slide-up-section {
    padding: 0 20px;
  }
}

@media screen and (max-width: 767.98px) {
  .home-hero {
    margin-top: 150px;
  }

  .features-card-container:nth-child(odd) {
    margin-bottom: 20px;
    flex-direction: column-reverse;
  }
  .features-card-container:nth-child(even) {
    margin-bottom: 20px;
    flex-direction: column;
  }

  .features-right .features-card {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .features-left .features-card {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .features-card video {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .table-header div:nth-child(n + 2) p {
    font-size: 30px;
  }
}

@media screen and (max-width: 600px) {
  /* helper class */
  .btn-mobile {
    display: inline-block !important;
  }
  .btn-dekstop {
    display: none !important;
  }

  .hero-img {
    width: 300px !important;
    height: 200px !important;
  }

  .home-content h1 {
    font-family: DM Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -1.6px;
    text-transform: capitalize;
    text-align: center;
  }

  .home-content p {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    margin: 20px 0;
  }

  .home-include {
    padding-top: 80px;
  }

  .include-title h1 {
    font-size: 40px;
    line-height: 40px; /* 100% */
    letter-spacing: -1.6px;
  }

  .include-title p {
    font-size: 16px;
    line-height: 28px; /* 175% */
  }

  .home-features {
    margin-top: 80px;
  }

  .features-title h1 {
    text-align: center;
    font-family: DM Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 100% */
    letter-spacing: -1.6px;
    text-transform: capitalize;
  }
  .features-title p {
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
  }

  .features-card-container {
    flex-direction: column;
  }

  .desc-wrapper img {
    width: 40px;
    height: 40px;
  }

  .features-desc h4 {
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.96px;
  }

  .features-desc p {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
  }

  .home-dashcam {
    margin-top: 80px;
  }

  .dashcam-title h1 {
    font-family: DM Sans;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 80% */
    letter-spacing: -2.4px;
    text-transform: capitalize;
    text-align: center;
  }

  .dashcam-title p {
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
    letter-spacing: 0.4px;
    text-align: center;
  }

  .home-dashcam .dk {
    flex-direction: column;
  }

  .home-dashcam .dk .dk-left img {
    order: 1;
    width: 300px !important;
    height: 200px !important;
    align-self: center;
    margin-bottom: 30px;
  }
  .home-dashcam .dk .dk-left img.dk25 {
    width: 300px !important;
    height: 150px !important;
  }

  .home-dashcam .dk .dk-left h1,
  .home-dashcam .dk .dk-left h2 {
    order: 2;
  }

  .home-dashcam .dk .dk-left h1 {
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.96px;
  }
  .home-dashcam .dk .dk-left h2 {
    font-family: DM Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
  }

  .home-dashcam .dk .dk-right .dk-desc img {
    margin-bottom: 15px;
    width: 40px;
    height: 40px;
  }

  .home-dashcam .dk .dk-right .dk-desc h5 {
    font-size: 20px;
  }
  .home-dashcam .dk .dk-right .dk-desc p {
    font-size: 14px;
  }

  .table-header div:first-child img {
    width: 122px;
    height: 40px;
  }

  .table-header div:nth-child(n + 2) p {
    font-size: 16px;
  }

  .table-content div:first-child span {
    font-size: 16px;
  }

  .table-content div:nth-child(n + 2) img {
    width: 24px;
    height: 24px;
  }

  .slide-up-section {
    display: none;
  }
}
