.container {
  padding: 40px 0;
  background: linear-gradient(180deg, #000a18 0%, #001838 45.32%, #000a18 100%);
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  border-radius: 20px;
  margin-top: 10px;
}
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
}
.headerTitle {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin: 0;
}
.featureItemContainer {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.featureItem {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 20px;
}
.featureItemDescription {
  margin: 0;
  font-size: 24px;
  text-align: center;
  font-family: Poppins;
}

@media screen and (max-width: 1199.98px) {
  .featureItemDescription {
    font-size: 16px;
  }
}

@media screen and (max-width: 767.98px) {
  .container {
    align-items: start;
    padding: 20px 0;
    border-radius: 20px;
  }
  .header {
    align-items: start;
    padding: 0 20px;
  }
  .featureItemContainer {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 40px;
  }
}

@media screen and (max-width: 600px) {
  .headerImage{
    width: 40px;
    height: 40px;
  }
}
