.cta {
  background: #001128;
  padding: 80px;
  margin-top: 160px;
}

.cta h1 {
  font-family: DM Sans;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 80% */
  letter-spacing: -2.4px;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.cta p {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  letter-spacing: 0.4px;
  color: #296386;
}

.cta-form {
  background-color: #001838;
  border-radius: 12px;
  padding: 40px;
  display: flex;
  gap: 20px;
}

.cta-form .form-left,
.cta-form .form-right {
  width: 50%;
}

.cta-form .form-left h1,
.cta-form .form-right h1 {
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.cta-form .form-left form div {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.cta-form .form-left form div span {
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.cta-form .form-left form div .error-message {
  color: #ff3131 !important;
  font-size: 14px !important;
  font-family: Poppins !important;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.cta-form .form-left form div .error-message::before {
  content: url("/public/danger.svg");
  width: 20px;
  height: 20px;
}

.cta-form .form-left form div input {
  border-radius: 4px;
  border: 1px solid #2b3e56;
  background: #001633;
  padding: 16px 24px;
}

.cta-form .form-left form div input[aria-invalid="true"] {
  outline: 2px solid #ff3131;
}

.cta-form .form-left form div textarea {
  border-radius: 4px;
  border: 1px solid #2b3e56;
  background: #001633;
  padding: 16px 24px;
}

.cta-form .form-left form div textarea[aria-invalid="true"] {
  outline: 2px solid #ff3131;
}

.cta-form .form-left .btn-kirim {
  border-radius: 4px;
  background: #44a3db;
  font-family: Poppins;
  font-size: 20px;
  color: #ecf6fb;
  font-weight: 600;
}

.cta-form .form-left .btn-kirim svg {
  animation: spin 1s linear infinite;
}

.form-right-info {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.info-item {
  display: flex;
  gap: 20px;
  align-items: center;
}

.info-item img {
  width: 35px;
  height: 35px;
}

.info-item span {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 991.98px) {
  .cta {
    padding: 80px 20px;
  }
}

@media screen and (max-width: 767.98px) {
  .cta-form {
    flex-direction: column;
    padding: 20px;
  }
  .cta-form .form-left,
  .cta-form .form-right {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .cta {
    margin-top: 80px;
  }

  .cta-form .form-left,
  .cta-form .form-right {
    width: 100%;
  }

  .cta h1 {
    font-family: DM Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 100% */
    letter-spacing: -1.6px;
    text-transform: capitalize;
  }
}
