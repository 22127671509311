.container {
  padding: 0 80px;
  margin-top: 122px;
}
.heading {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
}
.headingContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}
.articleTag {
  display: flex;
  gap: 16px;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}
.articleTagItem {
  padding: 0 16px;
  background: #000a18;
  border: 1px solid #b0b7c1;
  border-radius: 8px;
  font-size: 24px;
  font-family: Poppins;
  margin: 0;
}
.articleDate {
  font-family: Poppins;
  font-size: 24px;
  color: #44a3db;
  margin: 0;
}
.articleTitle {
  font-size: 60px;
  font-weight: 700;
  margin: 0;
  text-align: center;
}
.articleImage {
  width: 100%;
  aspect-ratio: 1.7/1;
  border-radius: 8px;
}
.content {
  margin-top: 40px;
  font-size: 20px;
}
@media screen and (max-width: 991.98px) {
  .container {
    padding: 0 20px;
  }
}

@media screen and (max-width: 767.98px) {
  .container {
    margin-top: 150px;
  }
  .articleTitle {
    font-size: 50px;
  }
}

@media screen and (max-width: 600px) {
  .heading {
    gap: 20px;
  }
  .articleTagItem {
    font-size: 16px;
  }
  .articleDate {
    font-size: 16px;
  }
  .articleTitle {
    font-size: 40px;
  }
  .content {
    margin-top: 20px;
    font-size: 16px;
  }
}
