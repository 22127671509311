.sectionTitle {
  margin: 0;
  font-weight: 700;
  font-size: 96px;
  text-align: center;
}

.newArticleContainer {
  padding: 0 80px;
  margin-top: 122px;
  background: linear-gradient(180deg, #000a18 0%, #001838 45.32%, #000a18 100%);
}
.cardArticleNewContainer {
  margin-top: 48px;
}
.articleContainer {
  padding: 0 80px;
  margin-top: 160px;
}
.cardArticleContainer {
  margin-top: 48px;
  display: grid;
  gap: 48px;
  grid-template-columns: repeat(auto-fill, minmax(384px, 1fr));
}

@media screen and (max-width: 991.98px) {
  .sectionTitle {
    font-size: 50px;
  }

  .newArticleContainer {
    padding: 0 20px;
  }
  .articleContainer {
    padding: 0 20px;
  }
}

@media screen and (max-width: 767.98px) {
  .newArticleContainer {
    margin-top: 150px;
  }
}

@media screen and (max-width: 600px) {
  .sectionTitle {
    font-size: 40px;
  }

  .articleContainer {
    margin-top: 80px;
  }

  .cardArticleContainer {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
