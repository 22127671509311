.parent {
  background: var(--surface-background, #000a18);
  height: 100%;
  padding: 0 80px;
  margin-top: 122px;
}

.header h1 {
  color: var(--text-heading, #ecf6fb);
  font-family: DM Sans;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px; /* 104.167% */
  letter-spacing: -7.68px;
  text-transform: capitalize;
}

.body-content .heading {
  color: var(--text-heading, #ecf6fb);
  font-family: DM Sans;
  font-size: 40px;
  font-weight: 700;
}

.row-one h2,
.row-two h2,
.row-three h2 {
  color: var(--text-heading, #ecf6fb);
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 137.5% */
}

.row-one ul li,
.row-two ul li,
.row-three ul li {
  color: var(--text-body, #93caea);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  letter-spacing: 0.4px;
  margin: 25px 0;
}

.row-form h2 {
  color: var(--text-heading, #ecf6fb);
  font-family: DM Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.6px;
  text-transform: capitalize;
}

.row-form p {
  color: var(--text-body, #93caea);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  letter-spacing: 0.4px;
}

.form-wrapper {
  border-radius: 20px;
  background: var(--surface-foreground, #001838);
  box-shadow: 0px 20px 32px 0px rgba(6, 22, 49, 0.4);
}

.label {
  color: var(--text-body, #93caea);
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
  letter-spacing: 0.8px;
}

.form-wrapper input,
.select-input,
.date-input {
  border-radius: 8px;
  border: 2px solid var(--border-inactive, #334660);
  background: var(--surface-background, #000a18);
  padding: 8px !important;
  color: var(--text-primary, #64b3e1) !important;
}

.note-label {
  color: var(--text-heading, #ecf6fb);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.btn-submit-garansi {
  border-radius: 24px 4px 24px 0px;
  background: var(--surface-accent, #44a3db);
  color: var(--text-heading-inverted, #000a18);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
}

.btn-submit-garansi:hover {
  border-radius: 24px 0px;
  border: 2px solid var(--border-primary, #64b3e1);
  background: var(--surface-background, #000a18);
  color: var(--text-primary, #64b3e1);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 767px) {
  .parent {
    background: var(--surface-background, #000a18);
    height: 100%;
    padding: 0 20px;
  }

  .header h1 {
    color: var(--text-heading, #ecf6fb);
    font-family: DM Sans;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 100px; /* 104.167% */
    letter-spacing: -2.68px;
    text-transform: capitalize;
  }

  .row-one h2,
  .row-two h2,
  .row-three h2 {
    color: var(--text-heading, #ecf6fb);
    font-family: DM Sans;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px; /* 137.5% */
  }

  .row-one ul li,
  .row-two ul li,
  .row-three ul li {
    color: var(--text-body, #93caea);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
    letter-spacing: 0.4px;
    margin: 25px 0;
  }

  .row-form h2 {
    color: var(--text-heading, #ecf6fb);
    font-family: DM Sans;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -1.6px;
    text-transform: capitalize;
  }

  .row-form p {
    color: var(--text-body, #93caea);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
    letter-spacing: 0.4px;
  }

  .form-wrapper {
    padding: 20px !important;
  }

  .note-label {
    color: var(--text-heading, #ecf6fb);
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .label {
    color: var(--text-body, #93caea);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
    letter-spacing: 0.8px;
  }
}
