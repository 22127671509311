.touchDisplay {
  margin-top: 160px;
  padding: 0 80px;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
}

.touchDisplayHeader {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
}
.touchDisplayHeader h2 {
  margin: 0;
  font-weight: 700;
  font-size: 60px;
}
.touchDisplayHeader p {
  color: #44A3DB;
  font-weight: 500;
  font-family: "Poppins";
  font-size: 24px;
  margin: 0;
}

.touchDisplayItems {
  display: grid;
  grid-template-columns: 1fr;
  gap: 80px;
}

.touchDisplayPreviewFull {
  width: 100%;
  aspect-ratio: 3/.9;
  overflow: hidden;
  position: relative;
}
.touchDisplayPreviewFull img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.touchDisplayBg {
  background: linear-gradient(180deg, #000A18 0%, #001838 45.32%, #000A18 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100px 100px 320px 320px;
  z-index: -1;
}
.touchDisplayPreviewX {
  display: grid;
  grid-template-columns: 1fr;
  gap: 4px;
  position: relative;
}
.touchDisplayPreviewX h4 {
  font-weight: 500; 
  font-size: 24px;
  margin: 0;
  font-family: "Poppins";
}
.touchDisplayPreviewXContent {
  width: 100%;
  aspect-ratio: 3.27/1;
  overflow: hidden;
  position: relative;
}
.touchDisplayPreviewXContent img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}
.touchDisplayPreviewXContentSlideLeft {
  width: 6%;
  aspect-ratio: 1/1;
  border-radius: 100%;
  background: #44A3DB;
  position: absolute;
  z-index: 1;
  top: 53%;
  left: 6%;
  outline: transparent;
  border: none;
}
.touchDisplayPreviewXContentSlideLeft::before {
  content: "";
  position: absolute;
  width: 120%;
  aspect-ratio: 1/1;
  background-color: rgba(64, 169, 243, 0.3); /* Semi-transparent circle */
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.touchDisplayPreviewXContentSlideLeft img {
  width: 46%;
  height: 61%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  animation: slides 2s infinite;
}
.touchDisplayPreviewXContentSlideRight {
  width: 6%;
  aspect-ratio: 1/1;
  border-radius: 100%;
  background: #44A3DB;
  position: absolute;
  z-index: 1;
  top: 53%;
  right: 15%;
  outline: transparent;
  border: none;
}
.touchDisplayPreviewXContentSlideRight::before {
  content: "";
  position: absolute;
  width: 120%;
  aspect-ratio: 1/1;
  background-color: rgba(64, 169, 243, 0.3); /* Semi-transparent circle */
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.touchDisplayPreviewXContentSlideRight img {
  width: 46%;
  height: 61%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  animation: slides 2s infinite;
}

.touchDisplayPreviewY {
  display: grid;
  grid-template-columns: 1fr;
  gap: 4px;
}
.touchDisplayPreviewY h4 {
  font-weight: 500; 
  font-size: 24px;
  margin: 0;
  font-family: "Poppins";
}
.touchDisplayPreviewYContent {
  width: 100%;
  aspect-ratio: 3.27/1;
  overflow: hidden;
  position: relative;
}
.touchDisplayPreviewYContent img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}
.touchDisplayPreviewYContentSlideUp {
  width: 6%;
  aspect-ratio: 1/1;
  border-radius: 100%;
  background: #44A3DB;
  position: absolute;
  z-index: 1;
  top: 26%;
  left: 12%;
  outline: transparent;
  border: none;
  transform: rotate(225deg);
}
.touchDisplayPreviewYContentSlideUp::before {
  content: "";
  position: absolute;
  width: 120%;
  aspect-ratio: 1/1;
  background-color: rgba(64, 169, 243, 0.3); /* Semi-transparent circle */
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.touchDisplayPreviewYContentSlideUp img {
  width: 46%;
  height: 61%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  animation: slides 2s infinite;
}
.touchDisplayPreviewYContentSlideDown {
  width: 6%;
  aspect-ratio: 1/1;
  border-radius: 100%;
  background: #44A3DB;
  position: absolute;
  z-index: 1;
  bottom: 13%;
  left: 12%;
  outline: transparent;
  border: none;
  transform: rotate(315deg);
}
.touchDisplayPreviewYContentSlideDown::before {
  content: "";
  position: absolute;
  width: 120%;
  aspect-ratio: 1/1;
  background-color: rgba(64, 169, 243, 0.3); /* Semi-transparent circle */
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.touchDisplayPreviewYContentSlideDown img {
  width: 46%;
  height: 61%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  animation: slides 2s infinite;
}

@keyframes slides {
  0%, 100% {
    left: 10%;
  }
  50% {
    left: 46%;
  }
}

@media screen and (max-width: 991.98px) {
  .touchDisplay {
    padding: 0 20px;
  }
}

@media screen and (max-width: 600px) {
  .touchDisplay {
    margin-top: 80px;
  }

  .touchDisplayHeader h2 {
    font-size: 40px;
  }
  .touchDisplayHeader p {
    font-size: 20 px;
  }

  .touchDisplayPreviewX h4 {
    font-size: 20px;
  }

  .touchDisplayPreviewY h4 {
    font-size: 20px;
  }
}
