.floatingwaWrapper {
  position: fixed;
  right: 0;
  bottom: 100px;
  transform: translateX(242px);
  z-index: 10;
  outline: none;
  padding: 16px;
  border: 1px solid #44A3DB;
  border-radius: 8px 0px 0px 8px;
  background: #001838;
  box-shadow: 0px 8px 16px 0px rgba(68, 163, 219, 0.40);

  display: flex;
  gap: 16px;
  align-items: center;

  transition: transform;
  transition-duration: 300ms;
}

.floatingwaWrapper h5 {
  margin: 0;
  padding: 0;
  color: transparent;
  font-size: 24px;
  font-weight: 700;
  transition: color;
  transition-duration: 300ms;
}

.floatingwaWrapper:hover {
  transform: translateX(2px);
}

.floatingwaWrapper:hover h5 {
  color: #ECF6FB;
}
