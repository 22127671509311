.container {
  background: linear-gradient(180deg, #000a18 0%, #001838 45.32%, #000a18 100%);
  padding: 0 80px;
  margin-top: 122px;
}

.tabsContainer {
  padding: 83px 20px;
  border-radius: 12px;
  background-image: url(/public/indonesia-maps.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}
.tabsContent {
  padding: 39px 40px;
  border-radius: 8px;
  background: #00183899;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.tabsContentTitle {
  margin: 0;
  font-weight: 700;
  font-size: 96px;
  text-align: center;
}
.tabsContentItems {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}
.tabsContentItem {
  padding: 4px 32px;
  border-radius: 4px;
  background: #000d1f;
  font-size: 16px;
  color: #ffffff;
  list-style: none;
  cursor: pointer;
}
.tabsContentItem[data-state-active="true"] {
  background: #44a3db;
  color: #ecf6fb;
}

/* * head office style */
.hoContainer {
  display: grid;
  grid-template-columns: 40% 1fr;
  gap: 40px;
}
.hoImageContainer {
  /* max-height: 700px; */
  overflow: hidden;
  border-radius: 20px;
}
.hoImage {
  width: 100%;
  height: 100%;
}
.hoContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.hoContentTitle {
  font-weight: 700;
  color: #64b3e1;
  font-size: 40px;
  margin: 0;
}
.hoContentAddress {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
  margin: 0;
}
.hoContentAddresItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.contentAddressItemDesc {
  margin: 0;
  font-size: 16px;
  font-family: Poppins;
  color: #fafafb;
}
.hoContentMapWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: auto;
}
.hoContentMaps {
  width: 100%;
  aspect-ratio: 2.5/1;
}
.hoButtonSeeMap {
  display: flex;
  padding: 12px 32px;
  border-radius: 8px;
  gap: 8px;
  align-items: center;
  background: #44a3db;
  color: #ecf6fb;
  font-weight: 600;
  font-size: 24px;
  font-family: Poppins;
  width: max-content;
}

/* * for branch and agen style */
.locationWrapper {
  display: grid;
  grid-template-columns: 403px 1fr;
  gap: 40px;
}
.locationSidebar {
  padding: 30px;
  border: 1px solid #334660;
  background: #001838;
  border-radius: 12px;
}
.locationItems {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0;
  margin: 0;
}
.locationItem {
  font-weight: 700;
  font-size: 32px;
  color: #296386;
  list-style: none;
  cursor: pointer;
}
.locationItem[data-state-active="true"] {
  color: #ecf6fb;
}
.locationToogleSidebar {
  display: none;
}
.locationContent {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(324px, 1fr));
  grid-auto-rows: min-content;
  gap: 40px;
}
.cardLocation {
  padding: 20px;
  background: #000a18;
  border: 1px solid #334660;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
}
.cardLocationContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.cardLocationTitle {
  font-weight: 700;
  font-size: 24px;
  color: #64b3e1;
  margin: 0;
}
.cardLocationContentItems {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin: 0;
  padding: 0;
}
.cardLocationContentItem {
  display: flex;
  gap: 16px;
  color: #fafafb;
  font-family: Poppins;
  list-style: none;
}
.cardLocationButton {
  display: flex;
  padding: 8px 24px;
  border-radius: 8px;
  gap: 8px;
  align-items: center;
  background: #44a3db;
  color: #ecf6fb;
  font-weight: 600;
  font-size: 24px;
  font-family: Poppins;
  width: max-content;
  box-shadow: 0px 8px 20px 0px #0094ff66;
  /* margin-top: auto; */
}

/* * sidebar style */
.sidebarWrapper {
  width: 80%;
  height: 100dvh;
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  transition: all;
  transition-duration: 500ms;
  background: #001838;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.sidebarOverlay {
  width: 100vw;
  height: 100dvh;
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  transition: all;
  transition-duration: 500ms;
}
.sidebarHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}
.sidebarTitle {
  color: #64B3E1;
  font-weight: 700;
  font-size: 40px;
  margin: 0;
}
.sidebarCloseButton {
  background: transparent;
  outline: none;
  border: none;
}
.sidebarItems {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
  margin: 0;
}
.sidebarItem {
  font-weight: 700;
  font-size: 32px;
  color: #296386;
  list-style: none;
  cursor: pointer;
}
.sidebarItem[data-state-active="true"] {
  color: #ecf6fb;
}


@media screen and (max-width: 991.98px) {
  .container {
    padding: 0 20px;
  }
  .hoContainer {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .hoImageContainer {
    aspect-ratio: 1/1;
  }
  .locationWrapper {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .locationSidebar {
    display: none;
  }
  .locationToogleSidebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    padding: 20px;
    border-radius: 12px;
    background: #001838;
    border: 1px solid #334660;
    font-weight: 700;
    font-size: 24px;
  }
}

@media screen and (max-width: 767.98px) {
  .container {
    margin-top: 150px;
  }
  .tabsContentTitle {
    font-size: 60px;
  }
  .tabsContent {
    width: 100%;
    padding: 20px;
  }
  .tabsContentItems {
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (max-width: 600px) {
  .tabsContainer {
    background-image: url(/public/indonesia-maps-small.webp);
  }
  .tabsContentTitle {
    font-size: 40px;
  }
  .tabsContainer {
    margin-bottom: 40px;
  }
  .locationContent {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 20px;
  }
}
