.footer-items {
  display: flex;
  gap: 40px;
  padding: 40px 80px;
  background: #001128;
}

.footer-item {
  background: none;
}

.footer-item h5 {
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 700;
  color: #64b3e1;
  white-space: nowrap;
  margin-bottom: 20px;
}

.footer-subscribe-get-on-store {
  display: flex;
  gap: 8px;;
}

.footer-media {
  width: 100%;
  display: flex;
  gap: 40px;
}

.footer-socmed,
.footer-product,
.footer-store {
  flex: 1;
}

.footer-subscribe p {
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 133.333%;
  margin: 20px 0;
}

.footer-email-input {
  width: 100% !important;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.footer-email-input input {
  padding: 8px 16px;
  border-radius: 8px;
  border: 2px solid #334660;
  color: #64b3e1;
  background-color: #000a18;
  flex: 2;
}

.footer-email-input input::placeholder {
  color: #64b3e1;
}

.footer-email-input input:focus {
  outline: none;
}

.footer-subs-btn {
  flex: 1;
  border-radius: 8px;
  background-color: #44a3db;
  color: #ecf6fb;
  font-family: Poppins;
  font-weight: 600;
  box-shadow: 0px 8px 12px 0px rgba(0, 148, 255, 0.4);
}

.footer-socmed-item,
.footer-store-item {
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin-bottom: 20px;
  color: #ecf6fb;
  text-decoration: none;
}

.footer-product-item {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin-bottom: 20px;
  color: #ecf6fb;
  text-decoration: none;
}

.footer-copyright {
  color: #296386;
  font-family: "Poppins";
  font-weight: 400;
  text-align: center;
  background-color: #000a18;
  margin: 20px 0;
}

.footer-socmed-item a,
.footer-product-item a,
.footer-store-item a {
  color: #ecf6fb;
  text-decoration: none;
}

@media screen and (max-width: 1200px) {
  .footer-items {
    flex-direction: column;
  }
}

@media screen and (max-width: 991.98px) {
  .footer-items {
    padding: 40px 20px;
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .footer-subscribe-get-on-store {
    flex-direction: column;
  }
  
  .footer-socmed-item img {
    width: 40px;
  }

  .footer-product-item img {
    width: 50px;
  }

  .footer-store-item img {
    width: 50px;
  }

  .footer-email-input {
    flex-direction: column;
  }

  .footer-email-input input,
  .footer-subs-btn {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .footer-media {
    flex-direction: column;
  }
}
