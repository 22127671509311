.nav-wraper {
  position: fixed;
  top: 0;
  z-index: 3;
  background: #000a18;
  width: 100vw;
}

.navbar-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 80px;
}

.navbar-route {
  display: flex;
  align-items: center;
  gap: 40px;
}

.navbar-routeLink {
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
  white-space: nowrap;
  color: #ecf6fb;
  background: none;
  outline: none;
  border: none;
}

.navbar-routeLink.activeLink {
  color: #64b3e1 !important;
  border-bottom: 1px solid #64b3e1;
}

.product-item-wraper {
  position: relative;
}

.nav-list {
  position: absolute;
  padding: 20px;
  background-color: #001838;
  top: 66px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.nav-list-item {
  text-decoration: none;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #ecf6fb;
  white-space: nowrap;
}

.nav-list-item h4 {
  margin-top: 4px;
}

.nav-list-item:not(:last-child) {
  padding-bottom: 20px;
  border-bottom: 2px solid #000d1f;
}

.nav-list-mobile {
  display: none;
}

.product-item-mobile {
  display: none;
}

@media screen and (max-width: 767.98px) {
  .navbar-parent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .navbar-parent img {
    width: 122px !important;
  }
  .product-item-wraper {
    display: none;
  }

  .nav-list {
    display: none;
  }

  .nav-list-mobile {
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    /* background-color: blue; */
    z-index: 3;
    top: 0;
    transition: all;
    transition-duration: 500ms;
  }

  .nav-list-mobile-wrapper {
    width: 80%;
    height: 100vh;
    margin-left: auto;
    background: #001838;
    padding: 40px 20px;
  }

  .nav-list-mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-list-mobile-header h3 {
    font-family: DM Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.04em;
    color: #64b3e1;
  }

  .nav-list-mobile-header button {
    background-color: #001838;
    outline: none;
    border: none;
  }

  .product-item-mobile {
    display: inline-block;
  }

  .nav-menu {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .nav-menu-item {
    text-decoration: none;
    font-family: Poppins;
    line-height: 28px;
    color: #ECF6FB;
  }

  .nav-menu-item:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 1px solid #307299
  }

  .nav-menu-item h4 {
    margin-top: 4px;
  }
}

@media screen and (max-width: 600px) {
  .navbar-route {
    gap: 20px;
  }

  .navbar-routeLink {
    font-size: 16px;
    font-weight: 400;
  }
}
