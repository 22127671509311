@import url(https://fonts.googleapis.com/css?family=DM+Sans:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

* {
  color: #ecf6fb;
  font-family: "DM Sans";
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: #000a18 !important;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

body::-webkit-scrollbar-track {
  background: #000a18;
}

body::-webkit-scrollbar-thumb {
  background: #44a3db;
}
